(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name form.controller:FormCtrl
   *
   * @description
   *
   */
  angular
    .module('form')
    .controller('FormCtrl', FormCtrl);

  function FormCtrl($stateParams, RCRUsers, Rcr, RcrParent, Locations, $filter, $localStorage, $location, $state, $anchorScroll, Alerts, Aside, $uibModal, $scope, Downloads, Events, Messages) {
    var vm = this;
    vm.ctrlName = 'FormCtrl';
	
	vm.showErrors = false;

    if(!RCRUsers.isAuthenticated()){$location.path('/');}

	vm.currentDate = moment().unix();
	
	vm.requestId = $stateParams.requestId;
	
	
	vm.csvColumnOrder = Downloads.csvColumnOrder;
	vm.csvHeader = Downloads.csvHeader;
	
	$scope.modal = {};
	
	vm.dateOptions = {
		minDate: moment().local()
	  };
	
	vm.openModals = function () {
		$scope.modal.title = "Change request " + vm.request.idsix + " status" ;
		$scope.modal.body = "Request " + vm.request.idsix + " is currently <strong>" + vm.request.Status.status + ".</strong> Please select the new status.";
		$scope.modal.statusRcr = {
			idsix: vm.request.idsix,
			statusOld: vm.request.Status.status,
			statusIdOld: vm.request.Status.id
		}
		$scope.modal.hasDismiss = true;
		$uibModal.open({
		  animation: true,
		  templateUrl: 'modal/modal.tpl.html',
		  size: 'md',
		  controller: 'ModalCtrl',
		  scope: $scope
		});

	  };

	vm.openMessages = function(formId){
		Aside.openAside(formId);
	}
	
	vm.saveRequest = function (requestData, isAdmin){
		var version = requestData.Rcr[0];
		
		version.regulatoryVersion = angular.toJson(version.regVersion);
		version.when = moment(version.whenFmt).unix();
		version.dateCreated = vm.currentDate;
		version.id = 0;
		
		if ($stateParams.requestId == "new"){
			RcrParent.create({
				"id": 0,
				"locationId": vm.reqLocation.id,
				"statusId": 1,
				"userId": $localStorage.loggedInUser.id,
				"dateCreated": vm.currentDate,
				"dateSubmitted":null
			}, function (parentResponse) {
				
				version.parentId = parentResponse.id;
				Rcr.create(version, function(childResponse){
					var idsixx = ('000000' + childResponse.parentId).slice(-6);
					Alerts.addAlert('ok','Request ' + idsixx + ' saved');
					
					Events.create({
						"rcrId": childResponse.id,
						"rcrParentId": childResponse.parentId,
						"userId": $localStorage.loggedInUser.id,
						"eventType":"rcr create", 
						"eventData":childResponse
					});
					$state.go('form', {'requestId': idsixx});
				});
			});
		}else{
			Rcr.create(version, function(childResponse){
				var idsixx = ('000000' + childResponse.parentId).slice(-6);
				Alerts.addAlert('ok','Request ' + idsixx + ' saved');
				if(isAdmin){
					//admin-save event
					Events.create({
						"rcrParentId": childResponse.parentId,
						"rcrId": childResponse.id,
						"eventType":"rcr admin save", 
						"eventData":childResponse,
						"userId": $localStorage.loggedInUser.id,
					});
				}else{
					//regular-save event - make sure json blob includes statusId of request parent so we can differentiate between status 1 & status 3 saves
					childResponse.status = vm.request.Status.id
					Events.create({
						"rcrParentId": childResponse.parentId,
						"rcrId": childResponse.id,
						"eventType":"rcr version", 
						"eventData":childResponse,
						"userId": $localStorage.loggedInUser.id,
					});
				}
			});
		}
		
	}
	
	
	vm.submitRequest = function (requestData){
		var version = requestData.Rcr[0];
		
		version.regulatoryVersion = angular.toJson(version.regVersion);
		version.when = moment(version.whenFmt).unix();
		version.dateCreated = vm.currentDate;
		version.id = 0;
		
		if ($stateParams.requestId == "new"){
			RcrParent.create({
				"id": 0,
				"locationId": vm.reqLocation.id,
				"statusId": 2,
				"userId": $localStorage.loggedInUser.id,
				"dateCreated": vm.currentDate,
				"dateSubmitted": vm.currentDate
			}, function (parentResponse) {
				version.parentId = parentResponse.id;
				Rcr.create(version, function(childResponse){
					Events.create({
						"rcrId": childResponse.id,
						"rcrParentId": childResponse.parentId,
						"userId": $localStorage.loggedInUser.id,
						"eventType":"rcr create submit", 
						"eventData":childResponse
					});
					var idsixx = ('000000' + childResponse.parentId).slice(-6);
					Alerts.addAlert('ok','Request ' + idsixx + ' submitted');
					$state.go('home');
				});
			});
		}else{
			
			RcrParent.upsert({
				"id": requestData.id,
				"statusId": 2,
				"dateSubmitted":requestData.dateSubmitted || vm.currentDate,
				"userId":requestData.userId,
				"locationId":requestData.locationId,
				"dateCreated":requestData.dateCreated
			}, function (parentResponse) {
				
				version.parentId = parentResponse.id;
				Rcr.create(version, function(childResponse){
					Events.create({
						"rcrId": childResponse.id,
						"rcrParentId": childResponse.parentId,
						"userId": $localStorage.loggedInUser.id,
						"eventType":"rcr submit", 
						"eventData":parentResponse
					});
					var idsixx = ('000000' + childResponse.parentId).slice(-6);
					Alerts.addAlert('ok','Request ' + idsixx + ' submitted');
					$state.go('home');
				});
			});
		}
	}
	
	vm.checkChecks = function (){
		if(!vm.request || !vm.request.Rcr[0] || !vm.request.Rcr[0].regVersion || vm.request.Rcr[0].regVersion == {}){
			vm.hasChecks = false;
		}else{
			vm.hasChecks = false;
			_.forEach(vm.request.Rcr[0].regVersion, function(reg, key){
				if (reg == true){
					vm.hasChecks=true;
					return false;
				}
			});
		}
	}
	if ($stateParams.requestId != "new"){
		if(isNaN($stateParams.requestId)){$location.path('/home');}
		RcrParent.find({"filter": {"include": [{"relation":"Rcr","scope": { "limit":1,"order": "id DESC"}},"Locations","Status"], "where": {"id":$stateParams.requestId},}}).$promise.then(
		function(results){
			vm.request = results[0];
			console.log(vm.request);
			Messages.getUnreadMessageCount({'rcrParentId':vm.request.id, 'userId':$localStorage.loggedInUser.id}, function (messageCount) {
				console.log(messageCount);
				vm.unreadMessageCount = messageCount[0].messageCount;
				vm.request.dateCreatedFmt = moment.utc(vm.request.dateCreated * 1000).local().format("DD MMM YY");
				vm.request.dateSubmittedFmt = moment.utc(vm.request.dateSubmitted * 1000).local().format("DD MMM YY");
				if (vm.request.dateApproved){
					vm.request.dateApprovedFmt = moment.utc(vm.request.dateApproved * 1000).local().format("DD MMM YY");
				}
				vm.request.Rcr[0].whenFmt = moment.unix(vm.request.Rcr[0].when).local().toDate();
				vm.request.Rcr[0].regVersion = angular.fromJson(vm.request.Rcr[0].regulatoryVersion);
				vm.request.idsix = ("000000" + vm.request.id).slice(-6);
				vm.reqLocation = vm.request.Locations;
				if (vm.reqLocation.id === 2) {
                    vm.csvColumnOrder = Downloads.cphCsvColumnOrder;
                    vm.csvHeader = Downloads.cphCsvHeader;
				}
				vm.checkChecks();
				if (vm.request.userId != $localStorage.loggedInUser.id || (vm.request.statusId != 1 && vm.request.statusId != 3)){
					vm.isEditable = false;
				}else if ((moment().unix() < vm.reqLocation.openDate || moment().unix() > vm.reqLocation.closeDate) && $localStorage.loggedInUser.userType == 1 && vm.request.statusId == 1){
					vm.isEditable = false;
				}else{
					vm.isEditable = true;
				}
			});			
		});
		
	}else{
		Locations.find({"filter": {"where": {"locationCode":{"like":$stateParams.location, "options":"i"}}}}).$promise.then(
		function(results){
			vm.reqLocation = results[0];
            if (vm.reqLocation.id === 2) {
                vm.csvColumnOrder = Downloads.cphCsvColumnOrder;
                vm.csvHeader = Downloads.cphCsvHeader;
            }

			if ((moment().unix() < vm.reqLocation.openDate || moment().unix() > vm.reqLocation.closeDate) && $localStorage.loggedInUser.userType == 1 && vm.request.statusId == 1){
				vm.isEditable = false;
			}else{
				vm.isEditable = true;
			}
		});
		
		vm.request = {"Rcr":[{}]};
		if($localStorage.loggedInUser.userType == 1){
		vm.request.Rcr[0].submitter = $localStorage.loggedInUser.firstName + " " + $localStorage.loggedInUser.lastName;
		vm.request.Rcr[0].department = $localStorage.loggedInUser.department;
		vm.request.Rcr[0].headOfDepartment = $localStorage.loggedInUser.headOfDepartment;
		}
	}
	
	vm.downloadCsv = function(rcrArray){
		return Downloads.rcrBuild(rcrArray);
	}
	
	
	
  }
}());
